<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmChatBotLLMFormData && Object.keys(vmChatBotLLMFormData).length > 0">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_name">
      {{cvChatbotLlmNameLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_name"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_family">
      {{cvChatbotLlmFamilyLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_family"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_api_key">
      {{cvChatbotLlmApiKeyLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_api_key"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_file_path">
      {{cvChatbotLlmFilePathLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_file_path"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_is_active">
      {{cvChatbotLlmIsActiveLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_is_active"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="chatbot_llmEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { ChatBotLLMs } from "../../../FackApi/api/ChatBotLLM"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "ChatBotLLMEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propChatBotLLMObj: {
      type: Object,
      default: function () {
        return {
          "chatbot_llm_name": "",
          "chatbot_llm_family": "",
          "chatbot_llm_api_key": "",
          "chatbot_llm_file_path": "",
          "chatbot_llm_is_active": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit chatbot_llm",
      cvCardSubHeader: "Edit chatbot_llm sub header",
      cvSubmitBtn: "Edit",
      cvChatbotLlmNameLabel: "chatbot llm name",
      cvChatbotLlmFamilyLabel: "chatbot llm family",
      cvChatbotLlmApiKeyLabel: "chatbot llm api key",
      cvChatbotLlmFilePathLabel: "chatbot llm file path",
      cvChatbotLlmIsActiveLabel: "chatbot llm is active",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "ChatBotLLM Updated",
      vmChatBotLLMFormData: {}
    }
  },
  mounted () {
    socialvue.index()
    this.chatbot_llmView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmChatBotLLMFormData) {
          if (!this.vmChatBotLLMFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * chatbot_llmView
     */
    async chatbot_llmView () {
      try {
        if (this.propOpenedInModal) {
          this.vmChatBotLLMFormData = this.propChatBotLLMObj
        }
        else {
          let chatbotLlmId = this.$route.params.cbllm_id
          let chatbotLlmViewResp = await ChatBotLLMs.chatbot_llmView(this, chatbotLlmId)

          if (chatbotLlmViewResp && chatbotLlmViewResp.resp_status) {
            this.vmChatBotLLMFormData = chatbotLlmViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_llmView() and Exception:", err.message)
      }
    },
    /**
     * chatbot_llmEdit
     */
    async chatbot_llmEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        console.log("SSY ", this.vmChatBotLLMFormData)
        let chatbotLlmAddResp = await ChatBotLLMs.chatbot_llmEdit(this, this.vmChatBotLLMFormData)
        await ApiResponse.responseMessageDisplay(this, chatbotLlmAddResp)

        if (chatbotLlmAddResp && !chatbotLlmAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseChatBotLLMEditModal", this.vmChatBotLLMFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_llmEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
