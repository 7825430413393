/*eslint-disable */
// import ChatBotLLMModel from '../../Model/ChatBotLLM'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let ChatBotLLMs = {
  /**
   * chatbot_llmList
   */
  async chatbot_llmList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "chatbot_llm_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at chatbot_llmList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * chatbot_llmView
   */
  async chatbot_llmView (context, chatbotLlmId) {
    try {      
      let post_data = new FormData();
      post_data.append('cbllm_id', chatbotLlmId);
      return await request.curl(context, "chatbot_llm_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at chatbot_llmView() and Exception:",err.message)
    }    
  },

  /**
   * chatbot_llmAdd
   */
  async chatbot_llmAdd (context, chatbot_llmObj) {
    try{
      let post_data = new FormData();
    
      for (let key in chatbot_llmObj) {
        post_data.append(key, chatbot_llmObj[key]);
      }

      return await request.curl(context, "chatbot_llm_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at chatbot_llmAdd() and Exception:',err.message)
    }
  },

  /**
   * chatbot_llmEdit
   */
  async chatbot_llmEdit (context, chatbot_llmObj) {
    try{
      let post_data = new FormData();
    
      for (let key in chatbot_llmObj) {
        post_data.append(key, chatbot_llmObj[key]);
      }

      return await request.curl(context, "chatbot_llm_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at chatbot_llmEdit() and Exception:',err.message)
    }
  },

  /**
   * chatbot_llmDelete
   */
  async chatbot_llmDelete (context, chatbotLlmId) {
    try{
      let post_data = new FormData();
      
      post_data.append('cbllm_id', chatbotLlmId);

      return await request.curl(context, "chatbot_llm_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at chatbot_llmDelete() and Exception:',err.message)
    }
  },
  /**
   * chatbot_llmSwitch
   */
  async chatbot_llmSwitch (context, chatbotLlmId) {
    try{
      let post_data = new FormData();
      
      post_data.append('cbllm_id', chatbotLlmId);

      return await request.curl(context, "chatbot_llm_switch", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at chatbot_llmSwitch() and Exception:',err.message)
    }
  }
}

export {
  ChatBotLLMs
}
